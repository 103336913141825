/* Copyright (C) 2022 TANNER AG */

@font-face {
    font-family: MAN-regular;
    font-display: swap;
    src: url(./fonts/maneuropecondensed_regular.woff2) format("woff2"), url(./fonts/maneuropecondensed_regular.woff);
    font-weight: 400;
}

@font-face {
    font-family: MAN-bold;
    font-display: swap;
    src: url(./fonts/maneuropecondensed_bold.woff2) format("woff2"), url(./fonts/maneuropecondensed_bold.woff);
    font-weight: 700;
}

@font-face {
    font-family: MAN-light;
    font-display: swap;
    src: url(./fonts/maneurope_light.woff2) format("woff2"), url(./fonts/maneurope_light.woff);
    font-weight: 400;
}

/* ----- html-content ---- */

/* IMAGE */
.html-content img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 #d6d8db;
}

/* UNORDERED LIST */

.html-content ul {
    padding-left: 18px;
    margin-top: 0;
}
.html-content ul > li {
    list-style-type: square;
}
.html-content ul > li::marker {
    color: #e40045;
    font-size: 18px;
}
.html-content ul > li > p:last-child {
    margin-bottom: 0;
}

/* TABLE */

.html-content table {
    width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    margin-bottom: 1rem;
    border: none;
}

.html-content table td, .html-content table th {
    font-size: 14px;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e2e3e4;
    border-top: 1px solid #e2e3e4;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    vertical-align: top;
}

.html-content table thead th {
    text-align: left;
    border-top: 2px solid #000;
    font-family: MAN-bold, sans-serif;
}

.html-content table td:first-child,
.html-content table th:first-child {
    border-left: none;
}

.html-content table td:last-child,
.html-content table th:last-child {
    border-right: none;
}

/* ----- PRINT ---- */

@page {
    size: A4 portrait;
    margin-top: 1.5cm;
    margin-bottom: 1.5cm;
}

@media screen {
    #print-container {
        display: none;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #print-container,
    #print-container * {
        visibility: visible;
    }

    #print-container {
        position: absolute;
        left: 0;
        top: 0;
        break-after: auto;
    }

    .print-section {
        break-before: page;
    }
}

/* ----- TENDER ---- */

mark {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: transparent;
}

.highlights mark[title=product] {
    background-color: rgba(0, 0, 255, 0.2);
}

.highlights mark[title=count] {
    background-color: rgba(255, 0, 0, 0.2);
}

.highlights mark[title=length] {
    background-color: rgba(255, 255, 0, 0.2);
}

.highlights mark[title=services] {
    background-color: rgba(255, 250, 0, 0.2);
}

.highlights mark[title=drive-e],
.highlights mark[title=drive-wa],
.highlights mark[title=drive-hybrid],
.highlights mark[title=drive-gas],
.highlights mark[title=drive-verbrenner] {
    background-color: rgba(0, 128, 0, 0.2);
}
